import { useDispatch } from 'react-redux';
import fm from 'format-message';
import textStyles from 'config/branding/textStyles';
import useAccountQuery from 'queries/useAccountQuery';
import Link from '@activebrands/core-web/components/Link';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import OverlayToggler from 'components/Header/OverlayToggler';
import LogOutIcon from 'components/icons/LogOutIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    bottom: 'var(--height-header)',
    zIndex: 'calc(var(--zindex-header) - 3)',
    position: 'fixed',
    width: '100%',
    backgroundColor: 'var(--color-bg-account)',

    [media.min['mobile.lg']]: {
        borderTop: 'none',
        borderLeft: 'none',
        border: '1px solid var(--color-border-account)',
        gridColumn: '1 / span 2',
        position: 'sticky',
        top: 0,
        width: '100%',
        height: `100vh`,
        minWidth: '356px',
    },
});

const Icon = styled('img', {
    marginRight: '8px',
    height: '16px',
    width: '16px',
});

const MyAccountNavigation = () => {
    const [css] = useStyletron();
    const dispatch = useDispatch();
    const { internalMenu, externalMenu } = useAccountQuery();

    const activeClass = css({
        color: 'var(--color-base-brand-grey-dark)',
    });

    return (
        <Wrapper>
            <ScrollableBox
                as="nav"
                className={css({
                    height: ' calc(100% - var(--height-header))',
                    display: 'flex',
                    flexDirection: ['row', null, 'column'],
                    justifyContent: 'space-between',
                    marginTop: [0, null, '54px'],
                })}
                scrolldirection="horizontal"
            >
                <ul
                    className={css({
                        display: 'flex',
                        flexDirection: ['row', null, 'column'],
                        flex: [1, null, 0],
                        padding: [0, null, '0px 12px'],
                    })}
                >
                    <li
                        className={css({
                            height: '100%',
                            width: '100%',
                            display: ['flex', null, 'none'],
                            border: '1px solid var(--color-border-menu-items)',
                            margin: '0px -1px',
                            justifyContent: 'center',
                            padding: '12px',
                        })}
                    >
                        <OverlayToggler overlayId="account-navigation">
                            <Paragraph fontKeys="Misc/16_100_-02">{fm('Menu')}</Paragraph>
                        </OverlayToggler>
                    </li>
                    {internalMenu &&
                        internalMenu.map((internalMenuItem, index) => (
                            <li
                                key={index}
                                className={css({
                                    height: '100%',
                                    width: '100%',
                                    border: ['1px solid var(--color-border-menu-items)', null, 'none'],
                                    padding: '13px 12px 13px 12px',
                                    marginRight: '-1px',
                                    ':hover': {
                                        color: 'var(--color-bg-button-link-hover)',
                                    },
                                })}
                            >
                                <Link
                                    activeClassName={activeClass}
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        height: '100%',
                                        justifyContent: ['center', null, 'start'],
                                        ...textStyles['Primary/32_-04'],
                                    })}
                                    rel="nofollow"
                                    to={internalMenuItem.link.url}
                                    onClick={overlay.close('account-navigation')}
                                >
                                    <Paragraph
                                        $style={{ whiteSpace: 'noWrap' }}
                                        as="p"
                                        fontKeys={['Misc/16_100_-02', null, 'Primary/32_-04']}
                                    >
                                        {internalMenuItem.label}
                                    </Paragraph>
                                </Link>
                            </li>
                        ))}
                </ul>
                <ul
                    className={css({
                        flexDirection: ['row', null, 'column'],
                        gap: '16px',
                        display: ['none', null, 'flex'],
                        marginBottom: '4px',
                    })}
                >
                    {externalMenu &&
                        externalMenu.map((externalMenuItem, index) => {
                            const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                            return (
                                <li
                                    className={css({
                                        height: '100%',
                                        width: '100%',
                                        padding: [0, null, '0 24px'],
                                        ':hover': {
                                            color: 'var(--color-bg-button-link-hover)',
                                        },
                                    })}
                                    key={index}
                                >
                                    <Link
                                        activeClassName={activeClass}
                                        className={css({
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            justifyContent: ['center', null, 'start'],
                                        })}
                                        rel="nofollow"
                                        to={externalMenuItem.link.url}
                                        onClick={overlay.close('account-navigation')}
                                    >
                                        {icon && <Icon src={icon} />}
                                        <Paragraph as="p" fontKeys="Regular-20/100">
                                            {externalMenuItem.label}
                                        </Paragraph>
                                    </Link>
                                </li>
                            );
                        })}
                    <li
                        className={css({
                            width: '100%',
                            borderTop: '1px solid var(--color-border-account)',
                            padding: [0, null, '16px 24px'],
                            marginBottom: ['56px', null, null, null, null, '0'],
                            ':hover': {
                                color: 'var(--color-bg-button-link-hover)',
                            },
                        })}
                    >
                        <Link
                            activeClassName={activeClass}
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer',
                            })}
                            rel="nofollow"
                            to="/"
                            onClick={() => dispatch(signOut())}
                        >
                            <LogOutIcon $style={{ marginRight: '8px' }} />
                            <Paragraph as="p" fontKeys="Regular-20/100">
                                {fm('Log out')}
                            </Paragraph>
                        </Link>
                    </li>
                </ul>
            </ScrollableBox>
        </Wrapper>
    );
};

export default MyAccountNavigation;
